<template>
  <div class="dashboard-component app-container">
    <div
      v-if="
        product.productTotal > 0 || device.deviceTotal > 0 || rule.ruleTotal > 0
      "
    >
      <el-row :gutter="20" class="card-line">
        <el-col :span="8">
          <div class="card-container" v-loading="product.loading">
            <div class="card-total">
              <div class="card-total-title">
                <div class="icon-line">
                  <img
                    src="../../../../assets/images/dashboard_product_icon.png"
                    class="title-icon"
                    alt=""
                  />
                  <span class="title-label">产品总数</span>
                </div>
                <div class="card-total-count">
                  <!-- <span> 56 </span> -->
                  <ICountUp
                    :endVal="product.productTotal"
                    :options="options"
                    class="card-total-count"
                  />
                </div>
              </div>
              <div class="card-total-list">
                <div
                  class="list-line"
                  @click="jumpToProductList('nodeType', 'DIRECTDEVICE')"
                >
                  <span class="line-label">直连设备</span>
                  <span class="line-count">
                    <span class="count-span">{{
                      product.directDeviceTotal
                    }}</span>
                    <span>
                      <i class="el-icon-arrow-right"></i>
                    </span>
                  </span>
                </div>
                <div
                  class="list-line"
                  @click="jumpToProductList('nodeType', 'GATEWAY')"
                >
                  <span class="line-label">网关设备</span>
                  <span class="line-count">
                    <span class="count-span">{{
                      product.gatewayDeviceTotal
                    }}</span>
                    <span>
                      <i class="el-icon-arrow-right"></i>
                    </span>
                  </span>
                </div>
                <div
                  class="list-line"
                  @click="jumpToProductList('nodeType', 'SUBDEVICE')"
                >
                  <span class="line-label">网关子设备</span>
                  <span class="line-count">
                    <span class="count-span">{{ product.subDeviceTotal }}</span>
                    <span>
                      <i class="el-icon-arrow-right"></i>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <el-divider class="card-divider"></el-divider>
            <div class="card-list">
              <div class="title-line">
                <span class="title-line-left">最近添加</span>
                <span class="title-line-right" @click="jumpToAddNew('product')">
                  <span>添加新产品</span>
                  <img
                    src="../../../../assets/images/dashboard_add_icon.png"
                    alt=""
                    class="add-icon"
                  />
                </span>
              </div>
              <!-- <el-skeleton :rows="3" :loading="product.loading" animated>
            <template slot="template">
              <el-skeleton-item
                class="table-line"
                variant="text"
                style="width: 100%"
              />
              <el-skeleton-item
                class="table-line"
                variant="text"
                style="width: 100%"
              />
              <el-skeleton-item
                class="table-line"
                variant="text"
                style="width: 100%"
              />
            </template>
          </el-skeleton> -->
              <div
                class="table-line"
                v-for="(item, index) in product.productList"
                :key="index"
              >
                <span
                  class="table-line-name"
                  @click="jumpToProductDetail(item)"
                  :title="item.productName"
                  >{{ item.productName }}</span
                >
                <span class="table-line-date">{{
                  item.creationDate | dateFormat
                }}</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="card-container" v-loading="device.loading">
            <div class="card-total">
              <div class="card-total-title">
                <div class="icon-line">
                  <img
                    src="../../../../assets/images/dashboard_device_icon.png"
                    class="title-icon"
                    alt=""
                  />
                  <span class="title-label">设备总数</span>
                </div>
                <div class="card-total-count">
                  <!-- <span> 7498 </span> -->
                  <ICountUp
                    :endVal="device.deviceTotal"
                    :options="options"
                    class="card-total-count"
                  />
                </div>
              </div>
              <div class="card-total-list">
                <div
                  class="list-line"
                  @click="jumpToDeviceList('deviceStatus', 'ONLINE', 'device')"
                >
                  <span class="line-label">
                    <smallCircle
                      :dataset="{
                        size: 12,
                        insideColor: '#36F4C7',
                        outSideColor: '#D1FCE6',
                      }"
                      class="small-circle-span"
                    />
                    在线
                  </span>
                  <span class="line-count">
                    <span class="count-span">{{ device.onlineTotal }}</span>
                    <span>
                      <i class="el-icon-arrow-right"></i>
                    </span>
                  </span>
                </div>
                <div
                  class="list-line"
                  @click="jumpToDeviceList('deviceStatus', 'OFFLINE', 'device')"
                >
                  <span class="line-label">
                    <smallCircle
                      :dataset="{
                        size: 12,
                        insideColor: '#C5C5C5',
                        outSideColor: '#E9E9E9',
                      }"
                      class="small-circle-span"
                    />
                    离线
                  </span>
                  <span class="line-count">
                    <span class="count-span">{{ device.offlineTotal }}</span>
                    <span>
                      <i class="el-icon-arrow-right"></i>
                    </span>
                  </span>
                </div>
                <div
                  class="list-line"
                  @click="
                    jumpToDeviceList('deviceStatus', 'INACTIVE', 'device')
                  "
                >
                  <span class="line-label">
                    <smallCircle
                      :dataset="{
                        size: 12,
                        insideColor: '#F4E136',
                        outSideColor: '#FCF5D1',
                      }"
                      class="small-circle-span"
                    />
                    未激活
                  </span>
                  <span class="line-count">
                    <span class="count-span">{{
                      device.unactivatedTotal
                    }}</span>
                    <span>
                      <i class="el-icon-arrow-right"></i>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <el-divider class="card-divider"></el-divider>
            <div class="card-list">
              <div class="title-line">
                <span class="title-line-left">最近添加</span>
                <span class="title-line-right" @click="jumpToAddNew('device')">
                  <span>添加新设备</span>
                  <img
                    src="../../../../assets/images/dashboard_add_icon.png"
                    alt=""
                    class="add-icon"
                  />
                </span>
              </div>
              <div
                class="table-line"
                v-for="(item, index) in device.deviceList"
                :key="index"
              >
                <span
                  class="table-line-name"
                  @click="jumpToDeviceDetail(item)"
                  :title="item.deviceName"
                  >{{ item.description || item.deviceName }}</span
                >
                <span class="table-line-date">{{
                  item.creationDate | dateFormat
                }}</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="card-container" v-loading="gateway.loading">
            <div class="card-total">
              <div class="card-total-title">
                <div class="icon-line">
                  <img
                    src="../../../../assets/images/dashboard_gateway_icon.png"
                    class="title-icon"
                    alt=""
                  />
                  <span class="title-label">网关总数</span>
                </div>
                <div class="card-total-count">
                  <!-- <span> 17 </span> -->
                  <ICountUp
                    :endVal="gateway.gatewayTotal"
                    :options="options"
                    class="card-total-count"
                  />
                </div>
              </div>
              <div class="card-total-list">
                <div
                  class="list-line"
                  @click="jumpToDeviceList('deviceStatus', 'ONLINE', 'gateway')"
                >
                  <span class="line-label">
                    <smallCircle
                      :dataset="{
                        size: 12,
                        insideColor: '#36F4C7',
                        outSideColor: '#D1FCE6',
                      }"
                      class="small-circle-span"
                    />
                    在线
                  </span>
                  <span class="line-count">
                    <span class="count-span">{{
                      gateway.gatewayOnlineTotal
                    }}</span>
                    <span>
                      <i class="el-icon-arrow-right"></i>
                    </span>
                  </span>
                </div>
                <div
                  class="list-line"
                  @click="
                    jumpToDeviceList('deviceStatus', 'OFFLINE', 'gateway')
                  "
                >
                  <span class="line-label">
                    <smallCircle
                      :dataset="{
                        size: 12,
                        insideColor: '#C5C5C5',
                        outSideColor: '#E9E9E9',
                      }"
                      class="small-circle-span"
                    />离线
                  </span>
                  <span class="line-count">
                    <span class="count-span">{{
                      gateway.gatewayOfflineTotal
                    }}</span>
                    <span>
                      <i class="el-icon-arrow-right"></i>
                    </span>
                  </span>
                </div>
                <div
                  class="list-line"
                  @click="
                    jumpToDeviceList('deviceStatus', 'INACTIVE', 'gateway')
                  "
                >
                  <span class="line-label">
                    <smallCircle
                      :dataset="{
                        size: 12,
                        insideColor: '#F4E136',
                        outSideColor: '#FCF5D1',
                      }"
                      class="small-circle-span"
                    />未激活
                  </span>
                  <span class="line-count">
                    <span class="count-span">{{
                      gateway.gatewayUnactivatedTotal
                    }}</span>
                    <span>
                      <i class="el-icon-arrow-right"></i>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <el-divider class="card-divider"></el-divider>
            <div class="card-list">
              <div class="title-line">
                <span class="title-line-left">最近添加</span>
                <span class="title-line-right" @click="jumpToAddNew('device')">
                  <span>添加新设备</span>
                  <img
                    src="../../../../assets/images/dashboard_add_icon.png"
                    alt=""
                    class="add-icon"
                  />
                </span>
              </div>
              <div
                class="table-line"
                v-for="(item, index) in gateway.gatewayList"
                :key="index"
              >
                <span
                  class="table-line-name"
                  @click="jumpToDeviceDetail(item)"
                  :title="item.deviceName"
                  >{{ item.description || item.deviceName }}</span
                >
                <span class="table-line-date">{{
                  item.creationDate | dateFormat
                }}</span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="card-line second-line">
        <el-col :span="8">
          <div class="card-container" v-loading="rule.loading">
            <div class="card-total">
              <div class="card-total-title">
                <div class="icon-line">
                  <img
                    src="../../../../assets/images/dashboard_rule_icon.png"
                    class="title-icon"
                    alt=""
                  />
                  <span class="title-label">规则总数</span>
                </div>
                <div class="card-total-count">
                  <!-- <span> 17 </span> -->
                  <ICountUp
                    :endVal="rule.ruleTotal"
                    :options="options"
                    class="card-total-count"
                  />
                </div>
              </div>
              <div class="card-total-list">
                <div class="list-line" @click="jumpToRuleList('ruleScope', '')">
                  <span class="line-label"> 启用的所有规则 </span>
                  <span class="line-count">
                    <span class="count-span">{{
                      rule.cloudEnableTotal + rule.edgeEnableTotal
                    }}</span>
                    <span>
                      <i class="el-icon-arrow-right"></i>
                    </span>
                  </span>
                </div>
                <div
                  class="list-line"
                  @click="jumpToRuleList('ruleScope', 'CLOUD')"
                >
                  <span class="line-label"> 启用云端规则数 </span>
                  <span class="line-count">
                    <span class="count-span">{{ rule.cloudEnableTotal }}</span>
                    <span>
                      <i class="el-icon-arrow-right"></i>
                    </span>
                  </span>
                </div>
                <div
                  class="list-line"
                  @click="jumpToRuleList('ruleScope', 'EDGE')"
                >
                  <span class="line-label"> 启用边缘规则数 </span>
                  <span class="line-count">
                    <span class="count-span">{{ rule.edgeEnableTotal }}</span>
                    <span>
                      <i class="el-icon-arrow-right"></i>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <el-divider class="card-divider"></el-divider>
            <div class="card-list">
              <div class="title-line">
                <span class="title-line-left">最近添加</span>
                <span class="title-line-right" @click="jumpToAddNew('rule')">
                  <span>添加新规则</span>
                  <img
                    src="../../../../assets/images/dashboard_add_icon.png"
                    alt=""
                    class="add-icon"
                  />
                </span>
              </div>
              <div class="detial-container">
                <div
                  class="table-line"
                  v-for="(item, index) in rule.ruleList"
                  :key="index"
                >
                  <span
                    class="table-line-name"
                    @click="jumpToRuleDetail(item)"
                    :title="item.ruleName"
                    >{{ item.ruleName }}</span
                  >
                  <span class="table-line-date">{{
                    item.creationDate | dateFormat
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="16">
          <div class="card-table-container">
            <alarmList />
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else>
      <DashboardEmpty @jumpToAddNew="jumpToAddNew" />
    </div>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";
import alarmList from "./alarmList.vue";
import DashboardEmpty from "./dashboardEmpty.vue";
import {
  getDashboardProductTotal,
  getDashboardDeviceTotal,
  getDashboardRuleTotal,
} from "@/api/ruge/vlink/dashboard/index.js";
import smallCircle from "@/components/SmallCircle";
export default {
  name: "dashboardComponent",
  components: {
    ICountUp,
    alarmList,
    smallCircle,
    DashboardEmpty,
  },
  data() {
    return {
      options: {
        duration: 0.6,
        useEasing: true,
        useGrouping: true,
        separator: "",
        decimal: ".",
        prefix: "",
        suffix: "",
        // numerals: ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"],
      },
      product: {
        loading: false,
        directDeviceTotal: 0,
        gatewayDeviceTotal: 0,
        subDeviceTotal: 0,
        productTotal: 0,
        productList: [],
      },
      device: {
        loading: false,
        deviceList: [],
        deviceTotal: 0,
        offlineTotal: 0,
        onlineTotal: 0,
        unactivatedTotal: 0,
      },
      rule: {
        loading: false,
        ruleList: [],
        ruleTotal: 0,
        cloudEnableTotal: 0,
        edgeEnableTotal: 0,
      },
      gateway: {
        loading: false,
        gatewayList: [],
        gatewayTotal: 0,
        gatewayOfflineTotal: 0,
        gatewayOnlineTotal: 0,
        gatewayUnactivatedTotal: 0,
      },
    };
  },
  created() {
    this.dataInit();
  },
  methods: {
    jumpToProductDetail(item) {
      this.$router.push({
        path: "/iot/product/detail",
        query: {
          productKey: item.productKey,
        },
      });
    },
    jumpToDeviceDetail(item) {
      this.$router.push({
        path: "/iot/device/detail",
        query: {
          productKey: item.productKey,
          deviceName: item.deviceName,
        },
      });
    },
    jumpToRuleDetail(item) {
      this.$router.push({
        path: "/iot/rule/detail",
        query: {
          ruleKey: item.ruleKey,
          type: "view",
        },
      });
    },
    jumpToRuleList(fieldName, fieldValue) {
      let queryParams = {};
      queryParams[fieldName] = fieldValue;
      queryParams["ruleStatus"] = "ENABLE";
      queryParams.rowCount = 10;
      sessionStorage.setItem(
        "rule_list_query_params",
        JSON.stringify(queryParams)
      );
      this.$router.push({
        path: "/iot/rule/list",
        query: {
          goBackFlag: true,
        },
      });
    },
    jumpToProductList(fieldName, fieldValue) {
      let queryParams = {};
      queryParams[fieldName] = fieldValue;
      sessionStorage.setItem(
        "product_list_query_params",
        JSON.stringify(queryParams)
      );
      this.$router.push({
        path: "/iot/product/list",
        query: {
          goBackFlag: true,
        },
      });
    },
    jumpToDeviceList(fieldName, fieldValue, type) {
      let queryParams = {};
      queryParams[fieldName] = fieldValue;
      queryParams.rowCount = 10;
      if (type === "gateway") {
        queryParams["nodeType"] = "GATEWAY";
      }
      sessionStorage.setItem(
        "device_list_query_params",
        JSON.stringify(queryParams)
      );
      this.$router.push({
        path: "/iot/device/list",
        query: {
          goBackFlag: true,
        },
      });
    },
    dataInit() {
      this.product.loading = true;
      this.device.loading = true;
      this.rule.loading = true;
      this.gateway.loading = true;
      getDashboardProductTotal()
        .then((res) => {
          const result = res;
          result.productList = result.productList.slice(0, 3);
          this.product = result;
        })
        .finally(() => {
          this.product.loading = false;
        });
      getDashboardDeviceTotal()
        .then((res) => {
          const result = res;
          result.deviceList = result.deviceList.slice(0, 3);
          result.gatewayList = result.gatewayList.slice(0, 3);
          this.device = result;
          this.gateway = result;
        })
        .finally(() => {
          this.device.loading = false;
          this.gateway.loading = false;
        });
      getDashboardRuleTotal()
        .then((res) => {
          const result = res;
          this.rule = result;
        })
        .finally(() => {
          this.rule.loading = false;
        });
    },
    jumpToAddNew(type) {
      const params = { type };
      switch (type) {
        case "product":
          this.$router.push({
            name: "VlinkProductList",
            params,
          });
          break;
        case "device":
          this.$router.push({
            name: "VlinkDeviceList",
            params,
          });
          break;
        case "rule":
          this.$router.push({
            path: "/iot/rule/detail",
          });
          break;
        case "gateway":
          this.$router.push({
            name: "VlinkDeviceList",
            params,
          });
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.dashboard-component {
  background-color: #f1f6fd;
  padding: 0;
  .card-line {
    display: flex;
    .card-container {
      padding: 20px;
      height: 330px;
      width: 100%;
      background-color: #fff;
      box-shadow: 2px 4px 20px 0px rgba(42, 65, 88, 0.1);
      border-radius: 8px;
      overflow: hidden;
      ::v-deep .el-loading-mask {
        border-radius: 8px;
      }
      .card-total {
        height: 138px;
        display: flex;
        & > div {
          flex: 1;
        }
        .card-total-title {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-bottom: 25px;
          .icon-line {
            display: flex;
            align-items: center;
            white-space: nowrap;
            .title-icon {
              height: 24px;
              width: 24px;
            }
            .title-label {
              font-size: 16px;
              font-weight: bold;
              color: #152c5b;
              margin-left: 5px;
            }
          }
          .card-total-count {
            font-size: 44px;
            font-weight: bold;
            color: #152c5b;
            margin-top: 20px;
          }
        }
        .card-total-list {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding-bottom: 25px;
          .list-line {
            height: 32px;
            line-height: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            white-space: nowrap;
            &:hover {
              .line-label {
                color: #1a4cec;
              }
              .line-count {
                color: #1a4cec;
              }
            }
            .line-label {
              font-size: 14px;
              font-weight: 400;
              color: #8190ac;
              .state-span {
                display: inline-block;
                height: 12px;
                width: 12px;
                border-radius: 50%;
                margin-right: 7px;
              }
              .state-online {
                background-color: #67c23a;
              }
              .state-offline {
                background-color: #e6a23c;
              }
              .state-notActive {
                background-color: #e4e7ed;
              }
              .small-circle-span {
                margin-right: 7px;
              }
            }
            .line-count {
              font-size: 14px;
              font-weight: 600;
              color: #303133;
              .count-span {
                margin-right: 10px;
              }
            }
          }
          .list-line + .list-line {
            margin-top: 10px;
          }
        }
      }
      .card-divider {
        margin: 0;
        background: #e9f0f5;
      }
      .card-list {
        .title-line {
          margin: 20px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title-line-left {
            font-size: 14px;
            font-weight: 400;
            color: #8190ac;
          }
          .title-line-right {
            cursor: pointer;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: #2a61ff;
            display: flex;
            align-items: center;
            border: 1px solid #fff;
            &:hover {
              border-bottom: 1px solid #1a4cec;
            }
            .add-icon {
              height: 14px;
              width: 14px;
              margin-left: 6px;
              position: relative;
              top: -2px;
            }
          }
        }
        .table-line {
          height: 20px;
          line-height: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .table-line-name {
            color: #2a61ff;
            cursor: pointer;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
            &:hover {
              text-decoration: underline;
            }
          }
          .table-line-date {
            display: inline-block;
            width: 90px;
            text-align: right;
            white-space: nowrap;
            color: #b5bece;
            font-size: 12px;
            font-weight: 400;
          }
        }
        .table-line + .table-line {
          margin-top: 16px;
        }
      }
    }
    // .card-container + .card-container {
    //   margin-left: 20px;
    // }
  }
  .second-line {
    margin-top: 20px;
    height: calc(100vh - 446px);
    display: flex;
    .card-container {
      height: 100%;
      width: 100%;
      .card-list {
        height: calc(100% - 140px);
        .detial-container {
          overflow: auto;
          height: calc(100% - 54px);
          .table-line {
            padding-right: 10px;
          }
        }
      }
    }
    .card-table-container {
      height: 100%;
      width: 100%;
      background: #fff;
      border-radius: 8px;
    }
  }
}
</style>