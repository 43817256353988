var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-empty-component" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        _vm._l(_vm.cardList, function (card, index) {
          return _c(
            "el-col",
            { key: index, attrs: { span: 8, lg: 8, sm: 12, xs: 12 } },
            [
              _c(
                "div",
                {
                  staticClass: "single-card",
                  on: {
                    click: function ($event) {
                      return _vm.jumpHandler(card)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "title-line" }, [
                    _c("img", { attrs: { src: card.icon, alt: "" } }),
                    _c("span", { staticClass: "title-right" }, [
                      _vm._v(
                        "\n            " + _vm._s(card.label) + "\n            "
                      ),
                      _c("i", { staticClass: "el-icon-arrow-right" }),
                    ]),
                  ]),
                  _c("div", { staticClass: "content-line" }, [
                    _vm._v(
                      "\n          " + _vm._s(card.tipsContent) + "\n        "
                    ),
                  ]),
                ]
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }