var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "small_circle_component" }, [
    _c(
      "span",
      {
        class: [
          _vm.dataset.blingOrNot && "bling_span",
          "out_side_circle circle_span",
        ],
        style: {
          background: _vm.dataset.outSideColor,
          height: _vm.dataset.size + "px",
          width: _vm.dataset.size + "px",
        },
      },
      [
        _c("span", {
          staticClass: "in_side_circle circle_span",
          style: {
            background: _vm.dataset.insideColor,
            height: _vm.dataset.size * 0.5 + "px",
            width: _vm.dataset.size * 0.5 + "px",
            top: _vm.dataset.size * 0.25 + "px",
            left: _vm.dataset.size * 0.25 + "px",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }