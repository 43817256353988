<template>
  <div class="dashboard-empty-component">
    <el-row :gutter="20">
      <el-col
        v-for="(card, index) in cardList"
        :key="index"
        :span="8"
        :lg="8"
        :sm="12"
        :xs="12"
      >
        <div class="single-card" @click="jumpHandler(card)">
          <div class="title-line">
            <img :src="card.icon" alt="" />
            <span class="title-right">
              {{ card.label }}
              <i class="el-icon-arrow-right"></i>
            </span>
          </div>
          <div class="content-line">
            {{ card.tipsContent }}
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "dashboardEmptyComponent",
  data() {
    return {
      cardList: [
        {
          type: "product",
          typeLabel: "产品",
          disable: false,
          icon: require("../../../../assets/images/dashboard_product_icon.png"),
          label: "添加产品",
          tipsContent:
            "在 Rlink 物联网平台接入设备前，您需要为同类设备建立一个产品，作为这些设备的集合。产品通常为同一个型号",
        },
        {
          type: "device",
          typeLabel: "设备",
          disable: true,
          icon: require("../../../../assets/images/dashboard_device_icon.png"),
          label: "添加设备",
          tipsContent:
            "为新建的产品添加设备。填写设备名称和设备识别码，即可添加新设备，并生成设备密钥",
        },
        {
          type: "rule",
          typeLabel: "规则",
          disable: true,
          icon: require("../../../../assets/images/dashboard_gateway_icon.png"),
          label: "添加规则",
          tipsContent:
            "为设备设置相关规则，可设置以下规则：联动规则、告警规则、时间表、设备通信规则和数据流转规则",
        },
      ],
    };
  },
  methods: {
    jumpHandler({ type, typeLabel }) {
      if (type === "product") {
        this.$emit("jumpToAddNew", "product");
      } else {
        this.$confirm(
          `在添加${typeLabel}前，需添加所属产品${
            type === "rule" ? "和设备" : ""
          }`,
          "提示",
          {
            confirmButtonText: "添加产品",
            cancelButtonText: this.$t("commons.cancel"),
            type: "info",
          }
        )
          .then(() => {
            this.jumpHandler({ type: "product" });
          })
          .catch(() => {
            console.log(`用户取消`);
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.dashboard-empty-component {
  .single-card {
    height: 192px;
    background-color: #fff;
    box-shadow: 2px 4px 20px 0px rgba(42, 65, 88, 0.1);
    border-radius: 8px;
    overflow: hidden;
    padding: 40px;
    margin-bottom: 20px;
    cursor: pointer;
    .title-line {
      display: flex;
      align-items: center;
      img {
        height: 32px;
        width: 32px;
        margin-right: 20px;
      }
      .title-right {
        color: #1a4cec;
        font-size: 16px;
        i {
          margin-left: 10px;
        }
      }
    }
    .content-line {
      padding-left: 52px;
      line-height: 25px;
      margin-top: 5px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #606266;
    }
  }
}
</style>