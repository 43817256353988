<template>
  <div class="small_circle_component">
    <span
      :class="[
        dataset.blingOrNot && 'bling_span',
        'out_side_circle circle_span',
      ]"
      :style="{
        background: dataset.outSideColor,
        height: dataset.size + 'px',
        width: dataset.size + 'px',
      }"
    >
      <span
        class="in_side_circle circle_span"
        :style="{
          background: dataset.insideColor,
          height: dataset.size * 0.5 + 'px',
          width: dataset.size * 0.5 + 'px',
          top: dataset.size * 0.25 + 'px',
          left: dataset.size * 0.25 + 'px',
        }"
      ></span>
    </span>
  </div>
</template>

<script>
export default {
  name: "smallCircleComponent",
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          size: 14,
          blingOrNot: false,
          outSideColor: "#0b67d930",
          insideColor: "#219df4",
        };
      },
    },
  },
};
</script>

<style lang="less" scoped>
.small_circle_component {
  display: inline-block;
  .circle_span {
    display: inline-block;
    border-radius: 50%;
  }
  .out_side_circle {
    position: relative;
  }
  .in_side_circle {
    position: absolute;
  }
  .bling_span {
    animation: blingbling 1.2s infinite linear;
  }
  @keyframes blingbling {
    20% {
      transform: scale(1.1);
    }
    40% {
      transform: scale(1.2);
    }
    60% {
      transform: scale(1.3);
    }
    80% {
      transform: scale(1.4);
    }
    90% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1.1);
    }
  }
}
</style>