var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dashboard-component app-container" }, [
    _vm.product.productTotal > 0 ||
    _vm.device.deviceTotal > 0 ||
    _vm.rule.ruleTotal > 0
      ? _c(
          "div",
          [
            _c(
              "el-row",
              { staticClass: "card-line", attrs: { gutter: 20 } },
              [
                _c("el-col", { attrs: { span: 8 } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.product.loading,
                          expression: "product.loading",
                        },
                      ],
                      staticClass: "card-container",
                    },
                    [
                      _c("div", { staticClass: "card-total" }, [
                        _c("div", { staticClass: "card-total-title" }, [
                          _c("div", { staticClass: "icon-line" }, [
                            _c("img", {
                              staticClass: "title-icon",
                              attrs: {
                                src: require("../../../../assets/images/dashboard_product_icon.png"),
                                alt: "",
                              },
                            }),
                            _c("span", { staticClass: "title-label" }, [
                              _vm._v("产品总数"),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "card-total-count" },
                            [
                              _c("ICountUp", {
                                staticClass: "card-total-count",
                                attrs: {
                                  endVal: _vm.product.productTotal,
                                  options: _vm.options,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "card-total-list" }, [
                          _c(
                            "div",
                            {
                              staticClass: "list-line",
                              on: {
                                click: function ($event) {
                                  return _vm.jumpToProductList(
                                    "nodeType",
                                    "DIRECTDEVICE"
                                  )
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "line-label" }, [
                                _vm._v("直连设备"),
                              ]),
                              _c("span", { staticClass: "line-count" }, [
                                _c("span", { staticClass: "count-span" }, [
                                  _vm._v(_vm._s(_vm.product.directDeviceTotal)),
                                ]),
                                _c("span", [
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "list-line",
                              on: {
                                click: function ($event) {
                                  return _vm.jumpToProductList(
                                    "nodeType",
                                    "GATEWAY"
                                  )
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "line-label" }, [
                                _vm._v("网关设备"),
                              ]),
                              _c("span", { staticClass: "line-count" }, [
                                _c("span", { staticClass: "count-span" }, [
                                  _vm._v(
                                    _vm._s(_vm.product.gatewayDeviceTotal)
                                  ),
                                ]),
                                _c("span", [
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "list-line",
                              on: {
                                click: function ($event) {
                                  return _vm.jumpToProductList(
                                    "nodeType",
                                    "SUBDEVICE"
                                  )
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "line-label" }, [
                                _vm._v("网关子设备"),
                              ]),
                              _c("span", { staticClass: "line-count" }, [
                                _c("span", { staticClass: "count-span" }, [
                                  _vm._v(_vm._s(_vm.product.subDeviceTotal)),
                                ]),
                                _c("span", [
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _c("el-divider", { staticClass: "card-divider" }),
                      _c(
                        "div",
                        { staticClass: "card-list" },
                        [
                          _c("div", { staticClass: "title-line" }, [
                            _c("span", { staticClass: "title-line-left" }, [
                              _vm._v("最近添加"),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "title-line-right",
                                on: {
                                  click: function ($event) {
                                    return _vm.jumpToAddNew("product")
                                  },
                                },
                              },
                              [
                                _c("span", [_vm._v("添加新产品")]),
                                _c("img", {
                                  staticClass: "add-icon",
                                  attrs: {
                                    src: require("../../../../assets/images/dashboard_add_icon.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._l(
                            _vm.product.productList,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "table-line" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "table-line-name",
                                      attrs: { title: item.productName },
                                      on: {
                                        click: function ($event) {
                                          return _vm.jumpToProductDetail(item)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item.productName))]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "table-line-date" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            item.creationDate
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.device.loading,
                          expression: "device.loading",
                        },
                      ],
                      staticClass: "card-container",
                    },
                    [
                      _c("div", { staticClass: "card-total" }, [
                        _c("div", { staticClass: "card-total-title" }, [
                          _c("div", { staticClass: "icon-line" }, [
                            _c("img", {
                              staticClass: "title-icon",
                              attrs: {
                                src: require("../../../../assets/images/dashboard_device_icon.png"),
                                alt: "",
                              },
                            }),
                            _c("span", { staticClass: "title-label" }, [
                              _vm._v("设备总数"),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "card-total-count" },
                            [
                              _c("ICountUp", {
                                staticClass: "card-total-count",
                                attrs: {
                                  endVal: _vm.device.deviceTotal,
                                  options: _vm.options,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "card-total-list" }, [
                          _c(
                            "div",
                            {
                              staticClass: "list-line",
                              on: {
                                click: function ($event) {
                                  return _vm.jumpToDeviceList(
                                    "deviceStatus",
                                    "ONLINE",
                                    "device"
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "line-label" },
                                [
                                  _c("smallCircle", {
                                    staticClass: "small-circle-span",
                                    attrs: {
                                      dataset: {
                                        size: 12,
                                        insideColor: "#36F4C7",
                                        outSideColor: "#D1FCE6",
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    "\n                  在线\n                "
                                  ),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "line-count" }, [
                                _c("span", { staticClass: "count-span" }, [
                                  _vm._v(_vm._s(_vm.device.onlineTotal)),
                                ]),
                                _c("span", [
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "list-line",
                              on: {
                                click: function ($event) {
                                  return _vm.jumpToDeviceList(
                                    "deviceStatus",
                                    "OFFLINE",
                                    "device"
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "line-label" },
                                [
                                  _c("smallCircle", {
                                    staticClass: "small-circle-span",
                                    attrs: {
                                      dataset: {
                                        size: 12,
                                        insideColor: "#C5C5C5",
                                        outSideColor: "#E9E9E9",
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    "\n                  离线\n                "
                                  ),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "line-count" }, [
                                _c("span", { staticClass: "count-span" }, [
                                  _vm._v(_vm._s(_vm.device.offlineTotal)),
                                ]),
                                _c("span", [
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "list-line",
                              on: {
                                click: function ($event) {
                                  return _vm.jumpToDeviceList(
                                    "deviceStatus",
                                    "INACTIVE",
                                    "device"
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "line-label" },
                                [
                                  _c("smallCircle", {
                                    staticClass: "small-circle-span",
                                    attrs: {
                                      dataset: {
                                        size: 12,
                                        insideColor: "#F4E136",
                                        outSideColor: "#FCF5D1",
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    "\n                  未激活\n                "
                                  ),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "line-count" }, [
                                _c("span", { staticClass: "count-span" }, [
                                  _vm._v(_vm._s(_vm.device.unactivatedTotal)),
                                ]),
                                _c("span", [
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _c("el-divider", { staticClass: "card-divider" }),
                      _c(
                        "div",
                        { staticClass: "card-list" },
                        [
                          _c("div", { staticClass: "title-line" }, [
                            _c("span", { staticClass: "title-line-left" }, [
                              _vm._v("最近添加"),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "title-line-right",
                                on: {
                                  click: function ($event) {
                                    return _vm.jumpToAddNew("device")
                                  },
                                },
                              },
                              [
                                _c("span", [_vm._v("添加新设备")]),
                                _c("img", {
                                  staticClass: "add-icon",
                                  attrs: {
                                    src: require("../../../../assets/images/dashboard_add_icon.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._l(_vm.device.deviceList, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "table-line" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "table-line-name",
                                    attrs: { title: item.deviceName },
                                    on: {
                                      click: function ($event) {
                                        return _vm.jumpToDeviceDetail(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        item.description || item.deviceName
                                      )
                                    ),
                                  ]
                                ),
                                _c("span", { staticClass: "table-line-date" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dateFormat")(item.creationDate)
                                    )
                                  ),
                                ]),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.gateway.loading,
                          expression: "gateway.loading",
                        },
                      ],
                      staticClass: "card-container",
                    },
                    [
                      _c("div", { staticClass: "card-total" }, [
                        _c("div", { staticClass: "card-total-title" }, [
                          _c("div", { staticClass: "icon-line" }, [
                            _c("img", {
                              staticClass: "title-icon",
                              attrs: {
                                src: require("../../../../assets/images/dashboard_gateway_icon.png"),
                                alt: "",
                              },
                            }),
                            _c("span", { staticClass: "title-label" }, [
                              _vm._v("网关总数"),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "card-total-count" },
                            [
                              _c("ICountUp", {
                                staticClass: "card-total-count",
                                attrs: {
                                  endVal: _vm.gateway.gatewayTotal,
                                  options: _vm.options,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "card-total-list" }, [
                          _c(
                            "div",
                            {
                              staticClass: "list-line",
                              on: {
                                click: function ($event) {
                                  return _vm.jumpToDeviceList(
                                    "deviceStatus",
                                    "ONLINE",
                                    "gateway"
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "line-label" },
                                [
                                  _c("smallCircle", {
                                    staticClass: "small-circle-span",
                                    attrs: {
                                      dataset: {
                                        size: 12,
                                        insideColor: "#36F4C7",
                                        outSideColor: "#D1FCE6",
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    "\n                  在线\n                "
                                  ),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "line-count" }, [
                                _c("span", { staticClass: "count-span" }, [
                                  _vm._v(
                                    _vm._s(_vm.gateway.gatewayOnlineTotal)
                                  ),
                                ]),
                                _c("span", [
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "list-line",
                              on: {
                                click: function ($event) {
                                  return _vm.jumpToDeviceList(
                                    "deviceStatus",
                                    "OFFLINE",
                                    "gateway"
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "line-label" },
                                [
                                  _c("smallCircle", {
                                    staticClass: "small-circle-span",
                                    attrs: {
                                      dataset: {
                                        size: 12,
                                        insideColor: "#C5C5C5",
                                        outSideColor: "#E9E9E9",
                                      },
                                    },
                                  }),
                                  _vm._v("离线\n                "),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "line-count" }, [
                                _c("span", { staticClass: "count-span" }, [
                                  _vm._v(
                                    _vm._s(_vm.gateway.gatewayOfflineTotal)
                                  ),
                                ]),
                                _c("span", [
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "list-line",
                              on: {
                                click: function ($event) {
                                  return _vm.jumpToDeviceList(
                                    "deviceStatus",
                                    "INACTIVE",
                                    "gateway"
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "line-label" },
                                [
                                  _c("smallCircle", {
                                    staticClass: "small-circle-span",
                                    attrs: {
                                      dataset: {
                                        size: 12,
                                        insideColor: "#F4E136",
                                        outSideColor: "#FCF5D1",
                                      },
                                    },
                                  }),
                                  _vm._v("未激活\n                "),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "line-count" }, [
                                _c("span", { staticClass: "count-span" }, [
                                  _vm._v(
                                    _vm._s(_vm.gateway.gatewayUnactivatedTotal)
                                  ),
                                ]),
                                _c("span", [
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _c("el-divider", { staticClass: "card-divider" }),
                      _c(
                        "div",
                        { staticClass: "card-list" },
                        [
                          _c("div", { staticClass: "title-line" }, [
                            _c("span", { staticClass: "title-line-left" }, [
                              _vm._v("最近添加"),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "title-line-right",
                                on: {
                                  click: function ($event) {
                                    return _vm.jumpToAddNew("device")
                                  },
                                },
                              },
                              [
                                _c("span", [_vm._v("添加新设备")]),
                                _c("img", {
                                  staticClass: "add-icon",
                                  attrs: {
                                    src: require("../../../../assets/images/dashboard_add_icon.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._l(
                            _vm.gateway.gatewayList,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "table-line" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "table-line-name",
                                      attrs: { title: item.deviceName },
                                      on: {
                                        click: function ($event) {
                                          return _vm.jumpToDeviceDetail(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          item.description || item.deviceName
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "table-line-date" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            item.creationDate
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "card-line second-line", attrs: { gutter: 20 } },
              [
                _c("el-col", { attrs: { span: 8 } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.rule.loading,
                          expression: "rule.loading",
                        },
                      ],
                      staticClass: "card-container",
                    },
                    [
                      _c("div", { staticClass: "card-total" }, [
                        _c("div", { staticClass: "card-total-title" }, [
                          _c("div", { staticClass: "icon-line" }, [
                            _c("img", {
                              staticClass: "title-icon",
                              attrs: {
                                src: require("../../../../assets/images/dashboard_rule_icon.png"),
                                alt: "",
                              },
                            }),
                            _c("span", { staticClass: "title-label" }, [
                              _vm._v("规则总数"),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "card-total-count" },
                            [
                              _c("ICountUp", {
                                staticClass: "card-total-count",
                                attrs: {
                                  endVal: _vm.rule.ruleTotal,
                                  options: _vm.options,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "card-total-list" }, [
                          _c(
                            "div",
                            {
                              staticClass: "list-line",
                              on: {
                                click: function ($event) {
                                  return _vm.jumpToRuleList("ruleScope", "")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "line-label" }, [
                                _vm._v(" 启用的所有规则 "),
                              ]),
                              _c("span", { staticClass: "line-count" }, [
                                _c("span", { staticClass: "count-span" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.rule.cloudEnableTotal +
                                        _vm.rule.edgeEnableTotal
                                    )
                                  ),
                                ]),
                                _c("span", [
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "list-line",
                              on: {
                                click: function ($event) {
                                  return _vm.jumpToRuleList(
                                    "ruleScope",
                                    "CLOUD"
                                  )
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "line-label" }, [
                                _vm._v(" 启用云端规则数 "),
                              ]),
                              _c("span", { staticClass: "line-count" }, [
                                _c("span", { staticClass: "count-span" }, [
                                  _vm._v(_vm._s(_vm.rule.cloudEnableTotal)),
                                ]),
                                _c("span", [
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "list-line",
                              on: {
                                click: function ($event) {
                                  return _vm.jumpToRuleList("ruleScope", "EDGE")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "line-label" }, [
                                _vm._v(" 启用边缘规则数 "),
                              ]),
                              _c("span", { staticClass: "line-count" }, [
                                _c("span", { staticClass: "count-span" }, [
                                  _vm._v(_vm._s(_vm.rule.edgeEnableTotal)),
                                ]),
                                _c("span", [
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _c("el-divider", { staticClass: "card-divider" }),
                      _c("div", { staticClass: "card-list" }, [
                        _c("div", { staticClass: "title-line" }, [
                          _c("span", { staticClass: "title-line-left" }, [
                            _vm._v("最近添加"),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "title-line-right",
                              on: {
                                click: function ($event) {
                                  return _vm.jumpToAddNew("rule")
                                },
                              },
                            },
                            [
                              _c("span", [_vm._v("添加新规则")]),
                              _c("img", {
                                staticClass: "add-icon",
                                attrs: {
                                  src: require("../../../../assets/images/dashboard_add_icon.png"),
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "detial-container" },
                          _vm._l(_vm.rule.ruleList, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "table-line" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "table-line-name",
                                    attrs: { title: item.ruleName },
                                    on: {
                                      click: function ($event) {
                                        return _vm.jumpToRuleDetail(item)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(item.ruleName))]
                                ),
                                _c("span", { staticClass: "table-line-date" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dateFormat")(item.creationDate)
                                    )
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 16 } }, [
                  _c(
                    "div",
                    { staticClass: "card-table-container" },
                    [_c("alarmList")],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          [_c("DashboardEmpty", { on: { jumpToAddNew: _vm.jumpToAddNew } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }