<template>
  <div class="alarm-list-component" v-loading="listLoading">
    <div class="title-line">
      <span class="table-title">告警列表</span>
      <span class="more-datas" @click="jumpToAlarmList()">
        查看更多<i class="el-icon-arrow-right"></i>
      </span>
    </div>
    <div class="table-title-line">
      <span
        class="table-header-label"
        v-for="(item, index) in listHeader"
        :key="index"
        :style="{
          flex: item.flex,
        }"
      >
        {{ item.label }}
      </span>
    </div>
    <div class="table-detail-container">
      <div
        v-for="(item, index) in alarmList"
        :key="index"
        class="detail-line"
        :style="{
          background: index % 2 === 1 ? '#F9FBFC' : '#fff',
        }"
      >
        <span
          class="detail-one jump-item"
          style="flex: 2"
          @click="jumpToAlarmList(item)"
        >
          {{ item.deviceDescription || "-" }}
        </span>
        <span class="detail-one" style="flex: 2">
          {{ item.alarmTimestamp | dateFormat("YYYY-MM-DD HH:mm:ss") }}
        </span>
        <span class="detail-one" style="flex: 3">
          {{ item.alarmContent }}
        </span>
        <span class="detail-one">
          {{ Contants.alarmLevel[item.alarmLevel] }}
        </span>
        <span class="detail-one">
          <el-tag :type="item.alarmStatus === 'alarm' ? 'danger' : 'success'">{{
            item.alarmStatus === "alarm" ? "告警" : "恢复"
          }}</el-tag>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { findAlarmPage } from "@/api/ruge/vlink/alarm/alarm";
import { Contants } from "@/constants/constant";
export default {
  name: "alarmListComponent",
  data() {
    return {
      Contants,
      listHeader: [
        {
          label: "设备名称",
          flex: 2,
        },
        {
          label: "告警时间",
          flex: 2,
        },
        {
          label: "告警内容",
          flex: 3,
        },
        {
          label: "告警级别",
          flex: 1,
        },
        {
          label: "告警状态",
          flex: 1,
        },
      ],
      alarmList: [],
      listLoading: false,
    };
  },
  created() {
    this.getAlarmList();
  },
  methods: {
    getAlarmList() {
      this.listLoading = true;
      findAlarmPage({ current: 1, rowCount: 10 })
        .then((res) => {
          if (res && res.rows && res.rows.length) {
            this.alarmList = res.rows;
          }
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    jumpToAlarmList(item) {
      if (!item) {
        sessionStorage.setItem("alarm_list_query_params", JSON.stringify({}));
        this.$router.push({
          path: "/iot/alarm/list",
        });
      } else {
        this.$router.push({
          path: "/iot/alarm/detail",
          query: {
            alarmId: item.alarmUUID,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.alarm-list-component {
  padding: 20px;
  height: 100%;
  .title-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .table-title {
      font-size: 16px;
      font-weight: bold;
      color: #152c5b;
    }
    .more-datas {
      font-size: 12px;
      font-weight: 400;
      color: #8190ac;
      cursor: pointer;
      .el-icon-arrow-right {
        margin-left: 6px;
      }
    }
  }
  .table-title-line {
    margin-top: 20px;
    padding: 0 30px;
    height: 42px;
    line-height: 42px;
    background: #f4f7f9;
    border-radius: 10px;
    display: flex;
    .table-header-label {
      font-size: 14px;
      font-weight: 400;
      color: #8190ac;
      flex: 1;
    }
  }
  .table-detail-container {
    overflow: auto;
    height: calc(100% - 78px);
    .detail-line {
      padding: 0 25px 0 30px;
      height: 56px;
      line-height: 56px;
      border-radius: 10px;
      display: flex;
      .detail-one {
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        font-weight: 400;
        color: #152c5b;
      }
      .jump-item {
        cursor: pointer;
        color: #2a61ff;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>