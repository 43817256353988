var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "alarm-list-component",
    },
    [
      _c("div", { staticClass: "title-line" }, [
        _c("span", { staticClass: "table-title" }, [_vm._v("告警列表")]),
        _c(
          "span",
          {
            staticClass: "more-datas",
            on: {
              click: function ($event) {
                return _vm.jumpToAlarmList()
              },
            },
          },
          [
            _vm._v("\n      查看更多"),
            _c("i", { staticClass: "el-icon-arrow-right" }),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "table-title-line" },
        _vm._l(_vm.listHeader, function (item, index) {
          return _c(
            "span",
            {
              key: index,
              staticClass: "table-header-label",
              style: {
                flex: item.flex,
              },
            },
            [_vm._v("\n      " + _vm._s(item.label) + "\n    ")]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "table-detail-container" },
        _vm._l(_vm.alarmList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "detail-line",
              style: {
                background: index % 2 === 1 ? "#F9FBFC" : "#fff",
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "detail-one jump-item",
                  staticStyle: { flex: "2" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpToAlarmList(item)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(item.deviceDescription || "-") +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "span",
                { staticClass: "detail-one", staticStyle: { flex: "2" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("dateFormat")(
                          item.alarmTimestamp,
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      ) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "span",
                { staticClass: "detail-one", staticStyle: { flex: "3" } },
                [_vm._v("\n        " + _vm._s(item.alarmContent) + "\n      ")]
              ),
              _c("span", { staticClass: "detail-one" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.Contants.alarmLevel[item.alarmLevel]) +
                    "\n      "
                ),
              ]),
              _c(
                "span",
                { staticClass: "detail-one" },
                [
                  _c(
                    "el-tag",
                    {
                      attrs: {
                        type:
                          item.alarmStatus === "alarm" ? "danger" : "success",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(item.alarmStatus === "alarm" ? "告警" : "恢复")
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }